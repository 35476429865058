.App {
  text-align: center;
  height: 100%;
}

.power {
  position: fixed;
  top: 0;
  right: 0;
  height: calc(10px + 2vmin);
  width: calc(10px + 2vmin);
  fill: #333;
  margin: 4vmin;
  padding: 1vmin;
  cursor: pointer;
  @media only screen and (min-width: 724px) {
    &:hover {
      background-color: #333;
      fill: #eee;
    }
  }
  &.on {
    background-color: #333333ee;
    fill: #eee;
  }
}