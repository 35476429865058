@import 'styles/colors';
.aboutContainer {
	color: $color-dark;
/*	margin: 4vmin;
	padding-bottom: 50vmin;
	
    @media only screen and (min-width: 724px) {
		padding-bottom: 0;
		height: calc(100% - 8vmin);
	}*/
	text-align: center;
	margin: 4vmin;
	p {
		max-width: 500px;
		@media only screen and (min-width: 724px) {
			max-width: 700px;
		}
	}
.parapraphHeader {
	background: opacify($color-dark, 0.9);
	color: $color-light;
	display: block;
}
.parapraphContent {
	display: block;
	margin-top: 1rem;
	padding-bottom: 4vmin;
}
}