@import 'styles/colors';

.AppLogo {
    height: 20vmin;
    width: 20vmin;
    /*pointer-events: none;*/
    border-radius: 50%;
}
.aboutContainer {
	color: $color-dark;
/*	margin: 4vmin;
	padding-bottom: 50vmin;
	
    @media only screen and (min-width: 724px) {
		padding-bottom: 0;
		height: calc(100% - 8vmin);
	}*/
	text-align: center;
	margin: 4vmin;
	p {
		max-width: 500px;
		@media only screen and (min-width: 724px) {
			max-width: 700px;
		}
	}
	.parapraphHeader {
		background: opacify($color-dark, 0.9);
		color: $color-light;
		display: block;
	}
	.parapraphContent {
		display: block;
		margin-top: 1rem;
		padding-bottom: 4vmin;
	}
}

/*@media (prefers-reduced-motion: no-preference) {
	.AppLogo:hover {
		animation: App-logo-spin infinite 10s ease;
	}
}

@keyframes App-logo-spin {
	0%, 100% {
		transform: rotate(0deg) scale(1);
	}
	50% {
		transform: rotate(10deg) scale(100.01);
		background-color: #444;
	}
}*/