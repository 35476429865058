@import 'styles/colors';

.nav {
    position: absolute;
    bottom: 0;
    //right: 0;
    //text-align: right;
    margin: 4vmin 0;
    display: flex;
    width: 100%;
    justify-content: center;

    div {
        cursor: pointer;
        padding: 0.5rem;
        fill: $color-dark;
        user-select: none;
        display: inline-block;

        @media only screen and (min-width: 724px) {
            &:hover {
                color: $color-light;
                background-color: $color-dark;
                opacity: (0.9);
                fill: $color-light;
            }
        }

        &.active {
            color: $color-light;
            background-color: $color-dark;
        }
        svg {
            width: calc(10px + 2vmin);
            height: calc(10px + 2vmin);
            vertical-align: middle;
        }
    }
}
