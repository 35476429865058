@import 'styles/colors';

a {
	text-decoration: none;
	color: $color-dark;
	&:hover {
		color: $color-light;
		background-color: $color-dark;
	}
}
.typewriter {
	justify-content: center;
	overflow: hidden; /* Ensures the content is not revealed until the animation */
	white-space: nowrap; /* Keeps the content on a single line */
	border-right: .07em solid transparent; /* The typwriter cursor */
	margin: 0 auto; /* Gives that scrolling effect as the typing happens */
	letter-spacing: .15em; /* Adjust as needed */
	animation: typing 1s steps(9, end),
	  blink-caret 0.75s step-end 4;
}

/* The typing effect */
@keyframes typing {
	from { width: 0 }
	to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
	from { border-color: $color-dark; }
	to, 50% { border-color: transparent; }
}