@import 'styles/colors';
$circle-size: 40vmin;
$triagnle-size-hypothenuse: 34vmin;
$triangle-size-other-sides: 20vmin;
$terminal-mode-scale-factor: 7;

.AppHeader {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AppCaption {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .07em solid $color-dark; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  letter-spacing: .15em; /* Adjust as needed */
  animation: blink-caret .75s step-end infinite;
  display: inline-flex;
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: $color-dark; }
}

.circle {
    height: $circle-size;
    width: $circle-size;
    background-color: $color-dark;
    pointer-events: none;
    border-radius: 50%;
}
.arrowUp {
  width: 0;
  height: 0;
  border-left: $triangle-size-other-sides solid transparent;
  border-right: $triangle-size-other-sides solid transparent;

  border-bottom: $triagnle-size-hypothenuse solid $color-dark;
  pointer-events: none;
}
.arrowDown {
  width: 0;
  height: 0;
  border-left: $triangle-size-other-sides solid transparent;
  border-right: $triangle-size-other-sides solid transparent;

  border-top: $triagnle-size-hypothenuse solid $color-dark;
  pointer-events: none;
}
.rectangleVertical {
  height: $circle-size;
  width: 0.5*$circle-size;
  background-color: $color-dark;
  pointer-events: none;

}

.rectangleHorizontal {
  height: 0.5*$circle-size;
  width: $circle-size;
  background-color: $color-dark;
  pointer-events: none;
}

.square {
  height: $circle-size;
  width: $circle-size;
  background-color: $color-dark;
  pointer-events: none;
}

.inlinable {
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.terminal {
  &.AppHeader {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: initial;
    justify-content: initial;
  }
  .inlinable {
    display: inline-flex;
    margin: 4vmin;
    flex-direction: initial;
  }
  .AppCaption {
    margin: 0;
    &:not(:first-child) {
      margin-left: 4vmin;
    }
    border: none;
  }
  .circle {
    height: $circle-size / $terminal-mode-scale-factor;
    width: $circle-size / $terminal-mode-scale-factor;
  }
  .arrowUp {
    border-left-width: $triangle-size-other-sides / $terminal-mode-scale-factor;
    border-right-width: $triangle-size-other-sides / $terminal-mode-scale-factor;
    border-bottom-width: $triagnle-size-hypothenuse / $terminal-mode-scale-factor;
  }
  .arrowDown {
    border-left-width: $triangle-size-other-sides / $terminal-mode-scale-factor;
    border-right-width: $triangle-size-other-sides / $terminal-mode-scale-factor;
    border-top-width: $triagnle-size-hypothenuse / $terminal-mode-scale-factor;
  }
}

.AppLogo {
  position: relative;
  $power-icon-size: calc(10px + 4vmin);
  svg {
    width: $power-icon-size;
    height: $power-icon-size;
    vertical-align: middle;
    fill: $color-light;
    position: absolute;
    top: calc(50% - #{$power-icon-size} / 2);
    left: calc(50% - #{$power-icon-size} / 2);
    display: none;
  }
}